<template>
  <div class='associated_user' v-loading="loading">
    <back />

    <div class='globle_border'>
      <div class="search">
        <div class="left">角色名称：{{ roleName }}</div>
        <div class="right">
          <el-input v-model="search1" placeholder="请输入搜索内容" suffix-icon="el-icon-search" @change="onSearch1" clearable></el-input>
          <el-button type="primary" @click="ConnectUser">关联用户</el-button>
        </div>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="userName" label="用户名称"></el-table-column>
          <el-table-column prop="userPhone" label="联系电话"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.switch ? '' : 'danger'">{{ scope.row.switch ? '正常' : '停用' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center">

            <template slot-scope="scope">
              <el-button class="btn" type="" plain @click="handleDeleteRole(scope.$index, scope.row)">取消授权</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- pagination1 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage1" :page-size="pageSize1" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount1">
    </el-pagination>

    <el-dialog title="关联用户" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-col :span="24" class="dialog_search">
        <el-input v-model="search2" placeholder="请输入搜索内容" suffix-icon="el-icon-search" @change="onSearch2" clearable></el-input>
      </el-col>
      <el-col :span="24">
        <div class="globle_table">
          <el-table :data="dialogTable" ref="multipleTable" height="400" tooltip-effect="dark" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="100"></el-table-column>
            <el-table-column prop="userName" label="用户名称"></el-table-column>
            <el-table-column prop="userPhone" label="联系电话"></el-table-column>
            <el-table-column label="状态">

              <template slot-scope="scope">
                <el-tag :type="scope.row.switch ? '' : 'danger'">{{ scope.row.switch ? '正常' : '停用' }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createdTime" label="创建时间"></el-table-column>
          </el-table>
        </div>
        <!-- pagination2 -->
        <el-pagination class="page2" background @size-change="dialogPageSizeChange" @current-change="dialogCurrentChange" :current-page.sync="currentPage2" :page-size="pageSize2" layout="prev, pager, next, jumper" :total="totalItemsCount2">
        </el-pagination>
      </el-col>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">关 联</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      id: null,
      roleName: '财务主管',
      search1: '',
      search2: '',
      checkedAll: null,
      dialogVisible: false,
      userIds: [],

      tableData: [],
      currentPage1: 1, // 当前页码
      pageSize1: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount1: 0, // 总条数

      dialogTable: [],
      currentPage2: 1, // 当前页码
      pageSize2: 10, // 每页显示的行数
      totalItemsCount2: 0, // 总条数
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getSysUserRoleListExist('exist')
  },
  methods: {
    // 角色详情
    getSysRole () {
      this.$axios.get(`${this.$api.getSysRole}/${this.id}`).then((res) => {
        this.roleName = res.data.result.roleName
      });
    },
    // 用户列表（已关联）
    getSysUserRoleListExist (type) {
      this.loading = true
      this.$axios.get(this.$api.getSysUserRoleList, {
        params: {
          roleId: this.id,
          userName: this.search1,
          page: this.currentPage1,
          pageSize: this.pageSize1,
          sysCode: this.$sysCode,
          selectType: type, // 查询类型 (已关联:exist 未关联:notExist 默认为已关联)
        }
      }).then((res) => {
        let list = res.data.result.list.map((e) => {
          return {
            ...e,
            switch: e.status == "1" ? true : false,
          };
        });
        this.tableData = list
        this.totalItemsCount1 = res.data.result.totalCount
        this.getSysRole()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 用户列表（未关联）
    getSysUserRoleListNotExist (type) {
      this.loading = true
      this.$axios.get(this.$api.getSysUserRoleList, {
        params: {
          roleId: this.id,
          userName: this.search2,
          page: this.currentPage2,
          pageSize: this.pageSize2,
          sysCode: this.$sysCode,
          selectType: type, // 查询类型 (已关联:exist 未关联:notExist 默认为已关联)
        }
      }).then((res) => {
        let list = res.data.result.list.map((e) => {
          return {
            ...e,
            switch: e.status == "1" ? true : false,
          };
        });
        this.dialogTable = list
        this.totalItemsCount2 = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 关联用户
    ConnectUser () {
      this.getSysUserRoleListNotExist('notExist')
      this.dialogVisible = true
    },
    // 搜索
    onSearch1 () {
      this.getSysUserRoleListExist('exist')
    },
    // 搜索（dialog）
    onSearch2 () {
      this.getSysUserRoleListNotExist('notExist')
    },
    //全选 or 全不选
    tableCheckedAll (value) {
      this.checkedAll = value
    },
    // 取消授权
    handleDeleteRole (index, row) {
      this.$confirm('此操作将永久取消该用户的权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(this.$api.deleteSysUserRole, {
          userId: row.userId,
          roleId: this.id,
          status: '9',
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc);
          }
          this.getSysUserRoleListExist('exist')
          this.getSysUserRoleListNotExist('notExist')
        }).catch((err) => {
          this.$message.error(err.data.desc);
        });
      }).catch(() => {
      });
    },
    // 改变表格行选中状态
    handleSelectionChange (row) {
      this.userIds = row.map(e => e.userId)
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize1 = e;
      this.getSysUserRoleListExist('exist')
    },
    // 换页
    handleCurrentChange () {
      this.currentPage1 += 1;
      this.getSysUserRoleListExist('exist')
    },
    // 切换每页显示的条数（dialog的分页）
    dialogPageSizeChange (e) {
      this.pageSize2 = e;
      this.getSysUserRoleListNotExist('notExist')
    },
    // 换页（dialog的分页）
    dialogCurrentChange () {
      this.currentPage2 += 1;
      this.getSysUserRoleListNotExist('notExist')
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false
    },
    // 关联用户
    submitForm () {
      this.$axios.post(this.$api.addSysUserRole, {
        roleId: this.id,
        userIds: this.userIds,
      }).then((res) => {
        this.$message.success(res.data.desc);
        this.getSysUserRoleListExist('exist')
        this.getSysUserRoleListNotExist('notExist')
        this.dialogVisible = false;
      }).catch((err) => {
        this.$message.error(err.data.desc);
      });
    },
  },
}
</script>

<style scoped lang='scss'>
.associated_user {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .left {
      color: #069BBC;
    }

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .dialog_search {
    margin-bottom: 30px;
    text-align: right;
  }

  .page2 {

    /deep/ .el-input,
    /deep/ .el-input__inner {
      width: 50px;
    }
  }
}
</style>
